<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <form id="CreateArea" @submit="CreateArea">
          <CCardHeader>
            <CCol col="6" style="font-size: 25px">إضافة منطقة </CCol>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CInput
                  class="required"
                  label="الاسم"
                  id="name"
                  v-model="Area.name"
                  placeholder="ادخل اسم المنطقة"
                  required
                />
              </CCol>
            </CRow>
            <div class="form-group">
              <label for="cities">
                المدن
                <span class="star">*</span>
              </label>
              <multiselect
                v-model="selected"
                :value="selected"
                id="cities"
                :options="cities"
                :searchable="true"
                selectLabel="اضغط لإختيار هذا العنصر"
                :close-on-select="true"
                selectedLabel=" العنصر المحدد"
                deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                openDirection="bottom"
                class="required"
                placeholder="اختر..."
                label="name"
                track-by="id"
                required
              >
                <span slot="noOptions">اللائحة فارغة</span>
                <span slot="noResult">لا توجد نتيجة</span>
              </multiselect>
              <!-- <select v-model="selected" id="cities" class="form-control" required>
                <option v-for="option in cities" v-bind:value="option.id">
                  {{ option.name }}
                </option>
              </select> -->
            </div>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" size="sm" color="primary"
              ><CIcon name="cil-check-circle" /> حفظ</CButton
            >
            &emsp;
            <CButton type="reset" size="sm" color="danger"
              ><CIcon name="cil-ban" /> مسح
            </CButton>
          </CCardFooter>
        </form>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import swal from "sweetalert";
import $ from "jquery";
export default {
  name: "CreateArea",
  data: function () {
    return {
      Area: {},
      image: "",
      cities: [],
      selected: "",
    };
  },
  created() {
    let cities = [];
    this.$http.get(`${process.env.VUE_APP_URL}cities-list`).then((response) => {
      console.log(response);
      $.each(response.data.data, function (key, value) {
        cities.push({ id: value.id, name: value.name });
      });
      this.cities = cities;
    });
  },
  methods: {
    CreateArea: function (e) {
      e.preventDefault();
      let currentObj = this;
      let formData = new FormData();
      formData.append("name", this.Area.name);
      if (this.selected.id) formData.append("city_id", this.selected.id);
      this.$http
        .post(`${process.env.VUE_APP_URL}areas`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          currentObj.output = response.data;
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: "تم" });
          } else {
            swal({ title: response.data.message, buttons: "تم" });
          }
        })
        .catch(function (error) {
          currentObj.output = error;
        });
    },
  },
};
</script>